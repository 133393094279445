@import "./Colours.scss";
@import "./Sparkle.scss";
@import "./Code.scss";
@import "./SectionBreak.scss";
@import "./Navigation.scss";
@import "./Carousel.scss";
@import "./Header.scss";
@import "./Landing.scss";
@import "./Home.scss";
@import "./Skills.scss";
@import "./Projects.scss";
@import "./IndividualProjects.scss";
@import "./Awards.scss";
@import "./Cultural.scss";
@import "./Contact.scss";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$font-family: "Poppins", sans-serif;
$base-font-size: 16px;

html {
	scroll-behavior: smooth;
}

body {
	margin: 0rem;
	background: $theme-dark;
	overflow-x: hidden;
}

p,
div {
	font-family: $font-family;
	font-size: $base-font-size;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #313442;
}

::-webkit-scrollbar-thumb {
	background: rgb(182, 182, 182);
	border-radius: 100rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: rgb(138, 138, 138);
}
