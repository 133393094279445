.section-container {
	text-align: center;
	margin: auto;

	padding: 2rem;
	max-width: 70rem;
	color: $theme-light;

	.section-title {
		margin-top: 4rem;
		font-size: 4rem;
		margin-bottom: 2rem;
		@media screen and (max-width: 800px) {
			font-size: 2.5rem;
		}
	}

	.section-sub-title {
		margin-top: 2rem;
		font-size: 2rem;
		margin-bottom: 1rem;
		@media screen and (max-width: 800px) {
			font-size: 1.5rem;
		}
	}

	.section-text {
		margin-bottom: 4rem;
	}

	.life-links {
		display: flex;
		justify-content: center;

		.life-link {
			margin: 0rem 2rem;
			width: 100%;
			max-width: 5rem;

			@media screen and (max-width: 800px) {
				margin: 0rem 1.5rem;
			}
		}
	}
}

.about-break {
	fill: $theme-black;
}

.skills-break {
	background: $theme-black;
	fill: $theme-dark;
}

.popup-content {
	animation: fadeIn 0.5s forwards;
	@media screen and (max-width: 800px) {
		max-width: 150px;
	}
}

.tooltip-button {
	fill: $theme-light;
	margin-left: 1rem;
}
