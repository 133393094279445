.projects {
    overflow: hidden;

    .projects-overview {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
        margin-bottom: 6rem;

        a {
            text-decoration: none;
        }

        .overview-content {
            transition: all 0.3s;
            color: $theme-light;

            &:hover,
            &:focus {
                cursor: pointer;
                transform: scale(1.05);
            }
        }

        .overview-title {
            margin-bottom: 1rem;
            font-weight: 500;

            @media screen and (max-width: 800px) {
                display: none;
                margin-bottom: 0rem;
            }
        }

        .overview-image {
            width: 100%;
            height: 12rem;
            object-fit: cover;
            border-radius: 1rem;

            @media screen and (max-width: 1200px) {
                max-width: 20rem;
                max-height: 12rem;
                height: 50%;
            }
        }

        @media screen and (max-width: 1200px) {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.project-sub-title-left {
    text-align: left;
}

.project-sub-title-right {
    text-align: right;
}

.project-sub-title-right,
.project-sub-title-left {
    margin: 0rem !important;

    @media screen and (max-width: 1200px) {
        margin-bottom: 1rem !important;
        text-align: center;
    }
}

.project-container {
    display: grid;
    margin-top: 4rem;
    column-gap: 2rem;

    .project-link-icon {
        height: 1.5rem;
        margin-left: 1rem;

        &:hover {
            cursor: pointer;
        }

        @media screen and (max-width: 800px) {
            height: 1rem;
        }
    }

    .project-list-item {
        width: 100%;
        height: 100%;
        max-height: 21rem;

        .project-image {
            width: 100%;
            height: 100%;
            border-radius: 1rem;
            object-fit: contain;
        }

        .legend {
            opacity: 0 !important;
            background: $theme-grey;
            padding: 0.25rem;
            bottom: 5px;
        }

        &:hover {
            .legend {
                opacity: 1 !important;
            }
        }
    }

    .project-text-container {
        display: grid;
        grid-template-rows: 4rem 2rem 1fr 6rem;

        @media screen and (max-width: 1200px) {
            display: block;
        }
    }

    .project-link-container {
        display: flex;
        margin-top: 1.25rem;

        .project-stack {
            display: flex;
            flex-direction: row;
            text-align: center;
            color: #ffffff;

            @media screen and (max-width: 1200px) {
                margin: auto;
            }

            .stack-title {
                margin-bottom: 0.25rem;
                margin: auto;
                margin-right: 1rem;
                margin-left: 0rem;
            }

            .stack-images {
                height: 1.5rem;
                padding: 0.5rem;
                border-radius: 1rem;
                background: #ffffff;
            }
            .language-icon {
                height: 100%;
                margin: 0rem 0.25rem;
            }
        }
    }

    .project-link-container-right {
        justify-content: flex-end;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        display: flex;

        .project-image-container {
            max-width: 30rem;
            margin: 2rem auto;
            margin-bottom: 4rem;
        }
    }
}

.project-container-left {
    grid-template-columns: 1fr 1fr;

    .project-text-left {
        text-align: left;
    }

    @media screen and (max-width: 1200px) {
        .project-text-left {
            text-align: center;
            margin-bottom: 0.5rem;
        }
    }
}

.project-container-right {
    grid-template-columns: 1fr 1fr;
    .project-text-right {
        text-align: right;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column-reverse;
        display: flex;
        .project-text-right {
            text-align: center;
            margin-bottom: 0.5rem;
        }
    }
}

.project-links {
    .link-container {
        display: flex;
        padding: 0.25rem;
        transition: background 0.2s;

        &:hover {
            cursor: pointer;
            background: $theme-grey;
        }

        .link-image {
            height: 1.5rem;
            margin-right: 0.5rem;
        }
    }
}
