.navigation {
    position: fixed;
    height: 55px;

    width: 100%;
    z-index: 100;
    background: #313442;

    transition: all 0.5s;

    .navigation-item {
        color: $theme-light;
        padding: 1rem 2rem;
        visibility: visible;
        transition: color 0.3s;
        text-decoration: none;

        &:hover {
            cursor: pointer;
            color: $theme-pink;
        }
    }

    .navigation-item-active {
        color: $theme-orange;
    }

    .navigation-item-container {
        display: flex;
        justify-content: center;
    }

    .navigation-item-container-mobile {
        display: block;
        background: #313442;

        .navigation-blocker {
            position: absolute;
            top: 0;
            width: 100%;
            height: 55px;
            background: #313442;
        }

        .navigation-item-first {
            margin-top: (calc(55px * -7));
            transition: padding 0.5s;
        }
        .navigation-item {
            text-align: center;
            background: #313442;
        }
    }

    .navigation-item-container-mobile-open {
        .navigation-item-first {
            padding-top: (calc(55px * 8));
        }
    }

    .hamburger {
        fill: $theme-light;
        margin: auto 0rem;
        visibility: hidden;
        position: absolute;
        right: 7px;
        top: 3px;
        @media screen and (max-width: 800px) {
            visibility: visible;
        }
    }
}
