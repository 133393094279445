.section-break {
	padding-top: 10rem;
	position: relative;
	width: 100%;
	margin-bottom: -2px;
	margin-top: -2px;

	.section-border {
		position: absolute;
		bottom: 0rem;
		width: 100%;
		margin-bottom: -2px;
	}
}
