$theme-dark: #1c1e26;
$theme-grey: #313442;
$theme-black: #0f1014;
$theme-light: #fde6e6;

$theme-yellow: #fcc5a8;
$theme-orange: #f7acc2;
$theme-pink: #ea9bc3;
$theme-blue: #75ceda;

// --gradient-color-1: #ff4d4d;
// --gradient-color-2: #fd7242;
// --gradient-color-3: #fba234;
// --gradient-color-4: #f9cb28;

// --gradient-color-1: #ff0054;
// --gradient-color-2: #ff4d4d;
// --gradient-color-3: #fba234;
// --gradient-color-4: #f9cb28;

// --gradient-color-1: #007cf0;
// --gradient-color-2: #009ce8;
// --gradient-color-3: #00c2df;
// --gradient-color-4: #00dfd8;

// --gradient-color-1: #3a86ff;
// --gradient-color-2: #00c2df;
// --gradient-color-3: #00dfd8;
// --gradient-color-4: #06d6a0;

// --gradient-color-1: #8f79f8;
// --gradient-color-2: #bf7df3;
// --gradient-color-3: #F977CE;
// --gradient-color-4: #F1A7F1;

// --gradient-color-1: #8f79f8;
// --gradient-color-2: #ff0054;
// --gradient-color-3: #fba234;
// --gradient-color-4: #fecb60;

// <svg width="100vw" height="100vh">
//     <clipPath id="svgPath">
//         <text x="50" y="40%" font-family="Inter" font-size="16rem" font-weight="900">LIAM</text>
//         <text x="50" y="60%"    font-family="Inter" font-size="16rem" font-weight="900">PERCY.</text>
//     </clipPath>
// </svg>
